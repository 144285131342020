import get_sample_data_list from '@/lib/data-service/default/get_sample_data_list';
import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api';

export default {
    components: {},
    data() {
        return {
            data_get_sample_data_list: {
                list: [],
            },
        }
    },
    methods: {
        __init() {
            const __this = this;
            get_sample_data_list().then(function (data) {
                __this.data_get_sample_data_list = data;
            });
        },

        __btn_view_click(pEntity, pEnableKeepAlive) {
            const __this = this;
            // history_tag_api.remove_tag_by_route({
            //     route: __this.$route,
            // });

            let name;
            if (pEnableKeepAlive) {
                name = `admin-sample-data-detail`;
            } else {
                name = `admin-sample-data-detail-disable-keep-alive`;
            }

            __this.$router.push({
                name,
                query: {
                    id: pEntity.id,
                },
            });

        },
    },
    created() {
    },
    mounted() {
        const __this = this;
        const $route = __this.$route;
        const enable_keep_alive = __this.enable_keep_alive;
        console.log(`mounted::$route.name=${$route.name}||enable_keep_alive=${enable_keep_alive}`);

        // enable_keep_alive = false时，在mounted进行初始化
        if (!enable_keep_alive) {
            __this.__init();
        }
    },
    activated() {
        const __this = this;
        const $route = __this.$route;
        const enable_keep_alive = __this.enable_keep_alive;
        console.log(`activated::$route.name=${$route.name}||enable_keep_alive=${enable_keep_alive}`);

        // enable_keep_alive = true时，在activated进行初始化
        if (enable_keep_alive) {
            __this.__init();
        }
    },
    deactivated() {
        // const __this = this;
    },
}
